import { UniDealerApiC } from '@unikey/unikey-commons/release/comm'
import { UniIdentityApiID } from '@unikey/unikey-commons/release/identity'

export const partnerKey: string = 'blw';
export const environment: string = 'staging';

const commNonceEndpoint = { nonceEndpoint: 'https://blw-stg-dealer-api.unikey.com/v5/nonces' };
export const api: UniDealerApiC = new UniDealerApiC('https://blw-stg-dealer-api.unikey.com', 'v5', 'blw'.toUpperCase(), { enableLogging: environment === 'development', preventRequestSubscriptions: '' as string === 'true', ...commNonceEndpoint })
export const identityApi: UniIdentityApiID = new UniIdentityApiID('https://identity-stg.unikey.com', 'api/v1', '167d44f1-72fb-4f7d-a7ec-290ac9c52d93', 'Ns5O00G+ShVoq5hATK9Za+D3Hr/vzMRtVTDjLN5xTpRbe5jzh5jMASBpozQ9PKH7B7qciVs8RCrb7dhOmMXDFw==', { enableLogging: environment === 'development' && false, preventRequestSubscriptions: '' as string === 'true', ...commNonceEndpoint });

export const captchaSiteKey: string = '6LemRAQhAAAAAE8OpHLkJrSzAhlecQzBSlHTzHOU';
export const captchaSecretKey: string = '6LemRAQhAAAAAB8sOrHMY3JWztymKaHnf7LqD4Qa';

export const termsOfServiceDocument: string = '/legal/termsofuse';
export const privacyPolicyDocument: string = '/legal/privacypolicy';
export const partnerSupportLink: string = 'https://bluewavesecurity.freshdesk.com/support/home';
export const deeplinkDomain = 'https://blw-stg-dealer-web.unikey.com'

export const appInsightsInstrumentationKey: string = '48c0549f-aa77-4468-84dc-1486885f952a';
export const mockAuth: string = '';

export const iosAppStoreLink: string = 'true';
export const googlePlayStoreLink: string = 'true';

export const oidcScope: string = 'openid email profile comm.dealer.api identity.api offline_access';
export const oidcClientId: string = '167d44f1-72fb-4f7d-a7ec-290ac9c52d93';
export const oidcAuthority: string = 'https://identity-stg.unikey.com/.well-known/openid-configuration';
export const oidcRedirectUri: string = 'https://blw-stg-web.unikey.com/#/token';
export const oidcRegisterUri: string = '';
export const oidcClientSecret: string = 'Ns5O00G+ShVoq5hATK9Za+D3Hr/vzMRtVTDjLN5xTpRbe5jzh5jMASBpozQ9PKH7B7qciVs8RCrb7dhOmMXDFw==';
export const oidcPartnerBrandId: string = 'eb703aec-2144-4c45-9bb9-04d4a20ae9c8';
export const oidcPartnerBrandCode: string = 'BLW';
export const oidcSilentRedirectUri: string = 'https://blw-stg-web.unikey.com/#/token';
export const oidcForgotPasswordRedirect: string = '';

export const buildId: string = '33792';
export const releaseId: string = 'Release-279';
export const cdpVersion: string = '5.3.1';
export const commonsVersion: string = '7.5.1';
export const desiredLocales: string = 'en,es,ja,fr,pt';